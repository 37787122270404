@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"),
    url(../assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url(../assets/fonts/Poppins-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(128, 127, 127);
  border-radius: 10px;
  transition: ease-in-out 500ms;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(95, 95, 95);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-15 {
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
